import React, { useEffect } from 'react';
import { usePress } from 'react-aria';
import { GridList, GridListItem, PressEvent } from 'react-aria-components';
import { DivProps } from 'react-html-props';
import { useIntl } from 'react-intl';
import CheckboxRadio from '../../../components/basic/CheckboxRadio';
import HoverCard from '../../../components/basic/HoverCard';
import { Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { isUndefinedOrNull, twMerge } from '../../../utils';
import Icon, { LineHeights } from '../icons/Icon';
import { Icons } from '../icons/iconTypes';


export type SecondaryAction = {
    type: SecondaryActionEnum,
    onSecondaryActionClick?(e): void;
    className?: string;
    customContent?: any;
}
export enum SecondaryActionEnum {
    Remove = 'remove',
    Delete = 'delete',
    Add = 'add',
    Edit = 'edit',
    Custom = 'custom',
}
export type MainAction = {
    type: MainActionEnum,
    onMainActionClick?(e): void,
}
export enum MainActionEnum {
    Remove = 'remove',
    Delete = 'delete',
    Add = 'add',
    Edit = 'edit',
}
export enum GraphicItems {
    Icon = 'icon',
    Thumbnail = 'thumbnail',
}
export enum SelectableType {
    Checkbox = 'checkbox',
    Radio = 'radio',
}
type MetaItemContent = {
    firstItem: string;
    secondItem?: string;
    icon?: Icons;
    className?: string;
}
type MetaItem = {
    metaAbove?: MetaItemContent;
    metaBelow?: MetaItemContent;
}
type InfoItem = {
    text: string | React.ReactElement; // Displays an info popup with string / element
    options?: { delay: number },
}
type IconRight = {
    name: string;
    onIconClick?(e);
}

type ArrowType = {
    type: ArrowTypeEnum;
    onArrowClick?(e): void;
}

export enum ArrowTypeEnum {
    Up = 'up',
    Down = 'down',
    Open = 'open',
    External = 'external',
}

interface Props extends DivProps {
    value?: ComponentOrStringType;
    icon?: Icons | React.ReactElement;
    iconClass?: string;
    thumbnail?: string; // TODO thumbnail type?
    graphicItemClass?: string;
    selectable?: {
        type: SelectableType,
        id: string;
        selected?: boolean;
        selectedColor?: string;
        selectedColorClass?: string;
        onChange?: React.FormEventHandler<HTMLInputElement>;
        onPress?(e: PressEvent): void;
    }
    mainAction?: MainAction;
    secondaryAction?: SecondaryAction;
    onPress?(e: PressEvent);
    className?: string;
    slim?: boolean;
    meta?: MetaItem;
    info?: InfoItem;
    inlineButton?: React.ReactElement; // TODO proper type
    iconRight?: IconRight; // // TODO Icon type and where to get icons from
    arrow?: ArrowType;
    children?: any;
}

const secondaryActionIcons: { [id: string]: Icons } = {
    add: 'operation_add',
    delete: 'operation_delete',
    remove: 'operation_remove',
    edit: 'operation_edit',
}
const mainActionIcons: { [id: string]: Icons } = {
    add: 'operation_add',
    delete: 'operation_delete',
    edit: 'operation_edit',
    remove: 'operation_remove',
}
const arrowIcons: { [id: string]: Icons } = {
    up: 'microinteraction_expand',
    down: 'microinteraction_collapse',
    open: 'microinteraction_open',
    external: 'microinteraction_external-link',
}
export const ListItem = (props: Props): React.ReactElement => {
    const { className, mainAction, secondaryAction, onPress, icon, iconClass, thumbnail, slim = false, selectable, meta, info, inlineButton, iconRight, arrow, ...rest } = props;
    const intl = useIntl();
    const borderClass = 'border-default';
    const commonClass = twMerge('h-full', slim ? 'p-2' : ' p-4');
    const actionItemClass = twMerge(borderClass, 'flex border-r h-full items-center', slim ? ' p-2' : ' p-4');
    const graphicItemClass = twMerge('py-2 px-0 justify-center', slim ? 'w-[41px]' : 'w-[55px]', props.graphicItemClass);
    const hasActionOrMeta = !isUndefinedOrNull(meta) || !isUndefinedOrNull(icon) || !isUndefinedOrNull(thumbnail);
    useEffect(() => {
        //
    }, []);

    const getInfoItem = () => {
        if (info) {
            const trigger = <Icon name='operation_get-info' className='cursor-default' />;
            return <div className='flex gap-x-1 items-center'><HoverCard
                trigger={<span>{trigger}</span>}
            >
                {info.text}
            </HoverCard>
            </div>
        }
    }
    const getGraphicItem = () => {
        const hasGraphic = icon || thumbnail;
        return hasGraphic && <div
            className={twMerge('border-r flex items-center justify-center w-[55px]', thumbnail ? 'h-[48px]' : 'h-full', props.graphicItemClass)}
        // className={twMerge(actionItemClass, graphicItemClass)}
        >
            {icon && (typeof icon === 'string') && <Icon name={icon as Icons} size={Sizes.XLarge} className={iconClass} />}
            {icon && (typeof icon !== 'string') && icon}
            {/* {thumbnail && <Image className='object-contain rounded-none w-full' url={thumbnail} /> */}
            {thumbnail && <img className='h-full w-full object-cover' src={thumbnail} />}

        </div>;
    }
    const getSelectable = () => {
        if (selectable) {
            if (selectable.type === SelectableType.Checkbox) {

                return <div className={twMerge(actionItemClass, selectable.selectedColorClass, 'items-center flex')} style={selectable.selected && selectable.selectedColor ? { backgroundColor: selectable.selectedColor } : {}}>
                    {/* TODO: ⬇️ This is a temporary hack to make the select width match the icon width */}

                    <div className='w-[1.5rem] h-[1.5rem] items-center justify-center flex'>
                        <CheckboxRadio.Checkbox className='' name={selectable.id} onChange={selectable.onChange} checked={selectable.selected} />
                    </div>
                </div>
            }
            if (selectable.type === SelectableType.Radio) {
                return <div className={twMerge(actionItemClass, selectable.selectedColorClass, 'items-center flex')} style={selectable.selected && selectable.selectedColor ? { backgroundColor: selectable.selectedColor } : {}}>
                    {/* TODO: ⬇️ This is a temporary hack to make the select width match the icon width */}
                    <div className='w-[1.5rem] h-[1.5rem] items-center justify-center flex'>
                        <CheckboxRadio.Radio name={selectable.id} onChange={selectable.onChange && selectable.onChange} checked={selectable.selected} />
                    </div>
                </div>
            }
        }
    }
    const getMetaItem = (type: 'above' | 'below') => {
        if (meta && type === 'above' && meta.metaAbove) {
            return <div className={twMerge('flex text-secondary text-xs items-center', meta.metaAbove.className)}>
                {meta.metaAbove.icon && <Icon size={Sizes.XSmall} className='mr-1' lineHeight={LineHeights.XSmall} name={meta.metaAbove.icon} />}
                {meta.metaAbove.firstItem}
                {meta.metaAbove.secondItem && <span className='space-x-1 pl-1'><span>•</span><span>{meta.metaAbove.secondItem}</span></span>}
            </div>;
        }
        if (meta && type === 'below' && meta.metaBelow) {
            return <div className={twMerge('flex text-secondary text-xs items-center', meta.metaBelow.className)}>
                {meta.metaBelow.icon && <Icon size={Sizes.XSmall} className='mr-1' name={meta.metaBelow.icon} />}
                {meta.metaBelow.firstItem}
                {meta.metaBelow.secondItem && <span className='space-x-1 pl-1'><span>•</span><span>{meta.metaBelow.secondItem}</span></span>}
            </div>;
        }
    }
    const getSecondaryAction = () => {
        return <div className={twMerge(borderClass, commonClass, 'flex items-center px-2 justify-center border-l cursor-pointer h-full',)}>
            {secondaryAction.type === SecondaryActionEnum.Custom && secondaryAction?.customContent}
            {secondaryAction.type !== SecondaryActionEnum.Custom && <Icon className={secondaryAction.className} onClick={secondaryAction.onSecondaryActionClick} name={secondaryActionIcons[secondaryAction.type]} />}
        </div>;
    }
    const getMainAction = () => {
        return <div className={twMerge('flex items-center justify-center px-2 h-full')}>
            {<Icon onClick={mainAction.onMainActionClick && mainAction.onMainActionClick} className='text-2xl text-brand cursor-pointer' name={mainActionIcons[mainAction.type]} />}
        </div>;
    }

    const getArrow = () => {
        return <div className='cursor-pointer pr-2'><Icon className='text-2xl text-secondary' name={arrowIcons[arrow.type] as Icons} /></div>
    }
    const getMainPadding = () => {
        let p;
        const getYPadding = () => {
            if (meta) {
                if (meta.metaAbove && meta.metaBelow) {
                    return 'py-2'
                }
                if (meta.metaAbove) {
                    return 'py-3';
                }
                return 'py-3'
            }
            return 'py-2';

        }
        if (slim) {
            p = meta ? getYPadding() + ' px-2' : 'p-2';
        } else {
            p = meta ? getYPadding() + ' px-4' : 'p-4';
        }
        // p = slim ? 'p-2' : 'p-4'
        return p;
    }
    const getInlineButton = () => {
        return <div className={twMerge('flex items-center justify-center pr-2')}>{inlineButton}</div>;
    }
    const { pressProps, isPressed } = usePress({
        onPress: (e) => {
            e.continuePropagation();
            onPress && onPress?.(e);
            selectable && selectable.onPress?.(e);
            mainAction && mainAction.onMainActionClick?.(e);
        }
    });
    return <QGridListItem>
        <div className={twMerge(borderClass, 'flex items-center border-b w-full', (selectable || mainAction) && 'cursor-pointer', className)} {...pressProps} {...rest}>
            {getSelectable()}
            {getGraphicItem()}
            <div className={twMerge('flex w-full flex-col', getMainPadding())}>
                <div>{getMetaItem('above')}</div>
                <div className={twMerge('flex gap-x-1')}>
                    {props.children}
                    {info && getInfoItem()}
                </div>
                <div>{getMetaItem('below')}</div>
            </div>
            {mainAction && getMainAction()}
            {secondaryAction && getSecondaryAction()}
            {arrow && getArrow()}
            {inlineButton && getInlineButton()}

        </div>
    </QGridListItem>

}
export const QGridListItem = (p: Props, { children, ...props }) => {
    const textValue = typeof children === 'string' ? children : undefined;
    return (
        <GridListItem textValue={textValue} {...props} className={({ isFocused, allowsDragging }) => twMerge('flex flex-col')}>
            {({ selectionMode, selectionBehavior, allowsDragging }) => (
                <div className='flex'>
                    {p.children}
                </div>
            )}
        </GridListItem>
    );
}

export const ListItems = GridList;

export default { ListItem, ListItems };
