import classnames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';
import { CorrectiveActionsState, MediaWithComment, ReportCheckpoint, ReportDefect } from '../../../../../backend_api/models';
import { AppState } from '../../../../../base/types';
import { getLocaleLanguageString, objectIsEmpty, twMerge, useIsMobileDevice } from '../../../../../base/utils';
import { CheckpointPhoto } from '../../../../checklists/types';
import Comments from '../../../../comments/components/Comments';
import WriteComment from '../../../../comments/components/WriteComment';
import { getCommentsByTypeSelector, showTranslatedCommentsSelector } from '../../../../comments/selectors';
import { CommentTypes } from '../../../../comments/types';
import getCommentsByDefect from '../../../../comments/util';
import { CorrectiveActionsFromDefectOrCheckpointsType } from '../../../../correctiveActions/components/CorrectiveActionsFromCheckpointsWizard';
import { TranslateText } from '../../../../globals/components';
import { InfoIcon } from '../../../../globals/components/Utils';
import { Inspection } from '../../../types';
import CheckpointComment from '../CheckpointComment';
import DefectsAndCheckpointsContextMenu from '../defects/DefectsAndCheckpointsContextMenu';
import InspectionReportCorrectiveAction from '../defects/InspectionReportCorrectiveAction';
import InspectionReportCorrectiveActionEvidenceMarker from '../defects/InspectionReportCorrectiveActionEvidenceMarker';
import InspectionReportDefect from '../defects/InspectionReportDefect';
import CheckpointImage from '../report2/CheckpointImage';
import CheckpointVideo from '../report2/CheckpointVideo';
import ImagesContainer from '../report2/ImagesContainer';
import Barcodes from './Barcodes';
import CheckpointInfo from './CheckpointInfo';
import MeasurementTables from './MeasurementTables';
import MultipleChoice from './MultipleChoice';
import TextInput from './TextInput';
import TextTable from './TextTable';

type OwnProps = {
    checkpoint: ReportCheckpoint;
    inspection: Inspection;
    isSub?: boolean;
};

type CheckpointItemProps = OwnProps;

export const getEvidenceImages = (evidenceImages: { url: string, ca_status: CorrectiveActionsState }[], inspection: Inspection, typeId: string, type: CommentTypes) => {
    return <>{evidenceImages.map((ei, i) => {
        return <CheckpointImage
            imageClassName={twMerge('border-6 rounded', 'border-ca-status-' + ei.ca_status)}
            imageURL={ei.url}
            inspection={inspection}
            typeId={typeId}
            type={type}
            key={'checkpointImage_' + ei.url}
            overlay={{
                content: <InspectionReportCorrectiveActionEvidenceMarker correctiveActionStatus={ei.ca_status} className='mt-[6px] ml-[6px] left-0 top-0' />,
                className: 'mt-[6px] ml-[6px] left-0 top-0'
            }}
        />
    })}</>
}
const CheckpointItem = (props: CheckpointItemProps): React.ReactElement => {
    const isMobile = useIsMobileDevice();
    const { checkpoint, inspection, isSub = false } = props;
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    const commentRef = useRef(null);
    const canCreateComments = inspection.features.includes('create_comments');
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const id = 'checkpoint_' + checkpoint.id;
    const isSubCheckpoint = isSub;
    const isCheckpointEmpty = false;
    const comments = useSelector((state: AppState) => getCommentsByTypeSelector(state, 'inspection', 'checkpoint', null));
    const dComments = getCommentsByDefect.getCommentsByCheckpoint(comments, checkpoint.id);
    const hasComments = !objectIsEmpty(dComments);
    const canCreateCorrectiveActionFeature = inspection.features.includes('corrective_action_for_quality.create');
    const canCreateCorrectiveAction = inspection.status === 'report' && canCreateCorrectiveActionFeature;

    let evidenceImages = [];
    checkpoint.corrective_actions.length > 0 && checkpoint.corrective_actions.map((ca) => {
        const f = ca.evidence.filter((ev) => ev.type === 'image').map((img) => ({ url: img.preview_url, ca_status: ca.state }));
        evidenceImages = [...evidenceImages].concat(f);
    })
    const getCheckpointImageComment = (photo: CheckpointPhoto): React.ReactElement | null => {
        return photo.comment ? <TranslateText
            showTranslatedComments={showTranslatedComments}
            translateObj={photo.translated_comment}
            originalLanguage={photo.language}
            originalText={photo.comment}
            showToggleActions={true}
        /> : null;
    }
    const getSubcheckpointHeader = (inspection: Inspection, subCheckpointInspectionId: string): string => {
        let heading = '-';
        inspection.sub_inspections.forEach((subInspection: Inspection) => {
            if (subInspection.inspection_id === subCheckpointInspectionId) {
                heading = intl.formatMessage({ id: 'inspections.subcheckpoint_header.order_no' }) + ': ' + subInspection.order_number + '. ' + subInspection.item_number + ': ' + subInspection.item_name;
            }
        });
        return heading;
    }
    const cn = classnames({ checkpointFullItem: true, checkpointSub: isSub });
    return <div className={cn}>
        <a id={id} />
        <Grid stackable>
            <Grid.Row>
                <Grid.Column width={isMobile ? 16 : 16} className='description'>
                    {isSubCheckpoint &&
                        <Fragment>
                            <Header as='h5'>{getSubcheckpointHeader(inspection, checkpoint.inspection_id)}</Header>
                            <Header as='h5'>{checkpoint.instruction_text[lang] || checkpoint.instruction_text.C}</Header>
                        </Fragment>
                    }
                    {!isSubCheckpoint && <Header as='h3'>
                        {checkpoint.instruction_text[lang] || checkpoint.instruction_text.C}
                        <CheckpointInfo className='pr-1' checkpoint={checkpoint} />
                    </Header>}
                    {!isCheckpointEmpty && <div className='flex flex-col items-start sm:flex-row sm:items-center justify-between w-full '>
                        <CheckpointComment className='mb-2 sm:mb-0' showIcon={true} showStatus={true} checkpoint={checkpoint as unknown as ReportCheckpoint} showTranslatedComments={showTranslatedComments} showToggleActions={true} />
                        <div className='flex sm:flex-0 sm:justify-end justify-start h-fit-content space-x-1 pb-4 sm:pb-2'>
                            <DefectsAndCheckpointsContextMenu className='py-0 bg-transparent border-none'
                                inspectionId={inspection.inspection_id}
                                itemId={checkpoint.id}
                                createOnType={CorrectiveActionsFromDefectOrCheckpointsType.Inspection}
                                itemType={'checkpoint'}
                                commentRef={commentRef}
                                comments={dComments}
                                type='checkpoint'
                                canCreateCorrectiveAction={canCreateCorrectiveAction}
                            />
                        </div>
                    </div>}
                    <div className='flex flex-col w-full my-2'>
                        {checkpoint.corrective_actions.map((ca) => {
                            return <InspectionReportCorrectiveAction inspectionId={inspection.inspection_id} key={'irca_' + ca.id + checkpoint.id} correctiveAction={ca} />
                        })}
                    </div>
                </Grid.Column>
            </Grid.Row>
            {!isCheckpointEmpty && checkpoint.barcode_table &&
                <Barcodes barcodes={checkpoint.barcode_table.barcodes} />
            }

            {checkpoint.defects && checkpoint.defects.length > 0 && <Grid.Row><div className='defect'>
                {checkpoint.defects.map((defect: ReportDefect, i: number) => {
                    return <InspectionReportDefect key={'ird_' + defect.id} defect={defect} inspectionId={inspection.inspection_id} inspection={inspection} />
                })}

            </div></Grid.Row>}

            {isCheckpointEmpty && <span className='noContent'><InfoIcon /><FormattedMessage id='inspections.checkpoints.no_content_on_checkpoint' /></span>}
        </Grid>
        {checkpoint.measurement_tables && <MeasurementTables tables={(checkpoint as unknown as ReportCheckpoint).measurement_tables} />}
        {checkpoint.multiple_choice && <MultipleChoice options={checkpoint.multiple_choice.options} />}
        {checkpoint.text_inputs && <TextInput textInputs={checkpoint.text_inputs} />}
        {checkpoint.text_table && <TextTable table={checkpoint.text_table} />}
        {isCheckpointEmpty && <span className='noContent'><InfoIcon /><FormattedMessage id='inspections.checkpoints.no_content_on_checkpoint' /></span>}
        {checkpoint.observed_photos && checkpoint.observed_photos.length === 0 && <div className='pt-8'>
            <ImagesContainer>
                {getEvidenceImages(evidenceImages, inspection, checkpoint.id, 'checkpoint_image')}
            </ImagesContainer>
        </div>}
        {checkpoint.observed_photos && checkpoint.observed_photos.length > 0 && <div className=''>
            <ImagesContainer>
                {checkpoint.observed_photos.map((image: MediaWithComment) => {
                    if (image.type === 'video') {
                        return <CheckpointVideo
                            image={image}
                            imageComment={getCheckpointImageComment(image)}
                            videoURL={image.video_resources[0].url}
                        />
                    }
                    else {
                        return <CheckpointImage
                            imageURL={image.url}
                            inspection={props.inspection}
                            typeId={checkpoint.id}
                            type='checkpoint_image'
                            imageComment={getCheckpointImageComment(image)}
                            key={'checkpointImage_' + image.url}
                        />
                    }
                })}
                {getEvidenceImages(evidenceImages, inspection, checkpoint.id, 'checkpoint_image')}
            </ImagesContainer>
        </div>}

        {hasComments && <div className='checkpoint-comments' ref={commentRef}>
            <Comments type='checkpoint' typeId={checkpoint.id} showCollapsed={false} hideZero={true} inspection={inspection} />
            {canCreateComments && <WriteComment showModal={false}
                id={checkpoint.id}
                type='checkpoint'
            />}
        </div>}
        {checkpoint.sub_checkpoints && checkpoint.sub_checkpoints.map((checkpoint) => {
            return <CheckpointItem checkpoint={checkpoint} inspection={inspection} isSub={true} key={'checkpointItem_' + checkpoint.id} />
        })}
    </div>
};

export default CheckpointItem;
