import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CorrectiveActionsState } from '../../../../../backend_api/models';
import Icon from '../../../../../base/components/basic/Icon';
import { twMerge } from '../../../../../base/utils';
import { getColor } from '../../../../correctiveActions/CorrectiveActionDisplayState';

type Props = {
    className?: string;
    correctiveActionStatus: CorrectiveActionsState;
};

const InspectionReportCorrectiveActionEvidenceMarker = (props: Props): React.ReactElement => {
    const { className, correctiveActionStatus } = props;
    const color = getColor(correctiveActionStatus);
    return <div className={twMerge('h-10 w-28 text-sm text-primary flex justify-center rounded-br brightness-125 items-center space-x-1', 'bg-' + color, className)}
    ><Icon name='assignment_turned_in' className={twMerge('text-base', 'brightness-75 text-' + color)} />
        <div><FormattedMessage id='corrective_actions.evidence' /></div>
    </div>
}
export default InspectionReportCorrectiveActionEvidenceMarker;
