import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { twMerge } from '../../../utils';

type Props = {
    className?: string;
    items: { w: number, text: string }[];
};

const ListSubHeading = (props: Props): React.ReactElement => {
    const { className, items } = props;
    const location = useLocation();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div className={twMerge('flex w-full text-sm text-secondary', className)}>
        {items.map((item) => {
            const cl = 'w-' + item.w + '/12';
            return <div className={cl}>{item.text}</div>
        })}
    </div>
}
export default ListSubHeading;
