import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { getPrettyDate } from '../../../base/utils';
import { ShallowOrder } from '../../../compliance_api/models';

type Props = {
    className?: string;
    order: ShallowOrder;
};

const ComplianceProductDeadline = (props: Props): React.ReactElement => {
    const { className, order } = props;
    // TODO Lukas: add days until deadline and other data to the deadlines, if required,
    const deadlinePassed = true;
    const hasDeadline = order.order_statistics && order.order_statistics;

    return hasDeadline ? <Popup trigger={
        <div className={twMerge('space-x-1', deadlinePassed && 'border-b-2 border-red-500')}>
            {/* TODO Lukas: Date is missing here. What date should this be? */}
            {/* <span>{getPrettyDate(order.)}.</span> */}
            <span>{order.order_number}</span>
        </div>}>
        <span>
            <FormattedMessage id={deadlinePassed ? 'compliance.order_list.deadline_missed_by_days' : 'compliance.products_list.deadline_days_until'}
                // TODO Lukas: Add days to deadline
                values={{ days: 5 }} /></span>
    </Popup> : null;
}
export default ComplianceProductDeadline;
